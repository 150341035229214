<template>
  <div class="question">
    <div class="sub-title d-flex justify-space-between text-sm-center" v-if="!isExplainPage">
      <span class="st-disagree designation">Strongly Disagree</span>
      <span class="st-agree designation">Strongly Agree</span>
    </div>
    <div v-else
         class="sub-title-onboarding d-flex justify-center">
      <span class="st-center designation">DRAG & RELEASE TO CONTINUE</span>
    </div>
    <v-slider
      :readonly="readOnly"
      v-model="answerCurrent"
      max="5"
      min="-5"
      step="0.5"
      height="85"
      hide-details
      thumb-color="#FF5151"
      thumb-label="always"
      :class="`tick-size`"
      @end="onSelectedQuestion"
      @mousedown="clickToSlider"
      @start="cancelNextQuestion"
      @mouseup="mouseUpSlider"
    >
      <template v-slot:thumb-label="{ value }">
        {{ clickedOnSlider ? Math.abs(value.toFixed(1)) : Math.abs(value) }}
      </template>
    </v-slider>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo" color-interpolation-filters="sRGB">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix in="blur" mode="matrix" type="matrix"
                         values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
          <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import { whenDone } from '@helpers/motionSliderAnimation';
import { mapGetters } from 'vuex';

const { detect } = require('detect-browser');

const browser = detect();

export default {
  name: 'QuestionSlider',
  props: {
    answer: {
      type: Number,
    },
    clickedOnSlider: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    isExplainPage: {
      type: Boolean,
      default: () => true,
    },
    isNextQuestion: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    finishAnimation: false,
    startAnimation: false,
    previousAsnwer: 0,
    previousDistance: 0,
    rerender: true,
  }),
  computed: {
    ...mapGetters({
      getLastAnswer: 'quiz/getLastAnswer',
    }),
    answerCurrent: {
      get() {
        if (this.answer === 0 && !this.clickedOnSlider && !!document.querySelector('#finish-answer')) {
          if (browser && (browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios')) {
            document.querySelector('#animation-ball-5').style.visibility = 'hidden';
          }
          document.querySelector('#finish-answer').remove();
        }

        return this.answer;
      },
      set(value) {
        this.$emit('update:answer', value);
      },
    },
  },
  watch: {
    answer() {
      const clickSlider = document.querySelector('.question .click-to-slider');
      if (clickSlider) {
        clickSlider.style.setProperty('--lightScaleStart', this.getLastAnswer.color);
      }
      if (document.querySelector('#animation-ball-1')) {
        // small add small balls
        document.querySelector('#animation-ball-5').style.left = `${this.answer * 10 + 50}%`;
        this.addAnimationPoints('#animation-ball-1');
        this.addAnimationPoints('#animation-ball-3');
        this.addAnimationPoints('#animation-ball-2');
      }
    },
    isNextQuestion() {
      if (this.clickedOnSlider && this.isNextQuestion && !document.querySelector('#finish-answer')) {
        // show animation finish answer
        const finishAnswer = document.createElement('div');
        finishAnswer.setAttribute('id', 'finish-answer');
        finishAnswer.classList.add('custom__thumb-slider');
        if ((browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios')) {
          document.querySelector('#animation-ball-5').style.visibility = 'hidden';
        }
        document.querySelector('.question .v-slider__thumb-label-container').appendChild(finishAnswer);
      }
    },
  },
  mounted() {
    whenDone(this.answer * 10 + 50);
    window.runScriptA = 0;
    document.querySelector('.v-slider__thumb-container')
      .addEventListener('touchstart', this.clickToSlider, false);
    document.querySelector('.v-slider__thumb-container')
      .addEventListener('touchend', this.mouseUpSlider, false);
  },
  methods: {
    addAnimationPoints(id) {
      setTimeout(() => {
        document.querySelector(id).style.left = `${this.answer * 10 + 50}%`;
      }, 20);
    },
    mouseUpSlider() {
      this.previousAsnwer = this.answer;
    },
    onSelectedQuestion(answer) {
      this.$emit('selected-question', answer);
      document.querySelector('.question .v-slider__thumb-label').classList.remove('clicked-to-slider');
      document.querySelector('.question .v-slider__thumb-label-container').classList.remove('click-to-slider');
    },
    clickToSlider() {
      document.querySelector('.question .v-slider__thumb-label').classList.add('clicked-to-slider');
      document.querySelector('#animation-ball-5').style.visibility = 'visible';
      this.$emit('click-slider');
    },
    cancelNextQuestion() {
      this.$emit('cancel-next-question');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~compass-mixins/lib/compass/css3';

::v-deep.question {
  height: 100%;
  padding: 0 28px;
  overflow: hidden;

  .v-slider__thumb {
    left: 0;
    height: 40px;
    width: 40px;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      display: none;
    }
  }
  .v-slider--horizontal {
    min-height: 85px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .animation-smack {
    position: absolute;
    transform: translate(-50%, -50%) !important;
    height: 100px !important;
    width: 100px !important;
    z-index: 1;
  }

  .clicked-to-slider {
    top: -40px !important;
    color: #FC6060 !important;
  }

  .click-to-slider {
    border-radius: 50%;
    animation: ripple 300ms ease-out forwards;
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0px var(--lightScaleStart), 0 0 0 0px var(--lightScaleStart),
      0 0 0 0px var(--lightScaleStart), 0 0 0 0px var(--lightScaleStart);
    }
    100% {
      box-shadow: 0 0 2px 8px var(--lightScaleStart), 0 0 2px 8px rgba(252, 96, 96, 0.9),
      0 0 3px 14px var(--lightScaleStart), 0 0 2px 16px rgba(252, 96, 96, 0.9);
    }
  }
  #animation-main {
    width: 100%;
  }

  .v-slider__thumb-container {
    z-index: 3;
  }

  .v-input__control {
    margin-left: 20px;
    margin-right: 20px;
  }

  .custom__thumb-slider {
    height: 86px;
    width: 86px;
    background: url("../../assets/sun.svg");
    transform: translate(-50%, -50%) scale(0.2);
    position: absolute;
    animation: finish 0.7s forwards;
    top: 50%;
    left: 50%;
  }

  @keyframes finish {
    0% {
      transform: translate(-50%, -50%) scale(0.2);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.2);
    }
  }

  .v-slider--horizontal .v-slider__thumb-label > * {
    -webkit-transform: none !important;
    transform: none !important;
  }

  .v-slider__thumb-label {
    font-family: $newDefaultFont;
    color: #ffffff;
    top: 3px;
    z-index: 2;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    transform: translateY(13%) translateX(20%) rotate(0deg) !important;
    background-color: transparent !important;
  }

  .st-center {
    justify-content: center;
  }

  .designation {
    font-style: normal;
    font-weight: 300;
    color: #767676;
    line-height: 16px;
    padding: 8px;
    font-size: 11px;
    position: relative;
    top: 93px;
  }

  .st-agree {
    left: 11px;
    text-align: right;
  }

  .st-disagree {
    right: 11px;
  }

  .v-slider__track-container {
    background: #D7D8D9 center;
    background-size: 100%;
    height: 4px;
    border-radius: 20px;

    .v-slider__track-background,
    .v-slider__track-fill {
      display: none;
    }
  }

  .tick-size {
    .v-slider__thumb {
      width: 45px;
      height: 45px !important;
    }
    .v-slider__thumb-label-container {
      transform: translate(-50%, -50%);
      width: 45px;
      height: 45px !important;
    }
  }
}
</style>
