/* eslint-disable */
// motionblurjs lib
const { detect } = require('detect-browser');
const browser = detect();

function whenDone(positionLeft) {
  let x = document.querySelectorAll(".question__select-result .v-slider__thumb-container");

  for (let i = 0; i < x.length; i++) {
    if(window.runScriptA !== 1){
      let y = x[i].parentNode.children;

      for (let r = 0; r < y.length; r++) {
        if(y[r].className.search("v-slider__thumb-container") >= 0){
          let generalClass = document.createElement('div');
          let element = document.createElement('div');
          let element2 = document.createElement('div');
          let element3 = document.createElement('div');
          let element6 = document.createElement('div');

          generalClass.setAttribute("id", "animation-main")

          if(browser && browser.name !== 'safari' && browser.name !== 'ios' && browser.name !== 'crios') {
            generalClass.style.cssText = 'filter: url(\'#goo\')';
          }

          element.setAttribute("id", "animation-ball-1");
          element2.setAttribute("id", "animation-ball-2");
          element3.setAttribute("id", "animation-ball-3");
          element6.setAttribute("id", "animation-ball-5");


          if(browser && (browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios')) {
            element.style.cssText += 'transition: .5s cubic-bezier(.25,.8,.5,1);border-radius: 50%;transform: translate(-50%, -47%);height:12px;width:12px;z-index:1;background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element2.style.cssText += 'transition: 1s cubic-bezier(.25,.8,.5,1);border-radius: 50%;transform: translate(-50%, -47%);height:4px;width:4px;z-index:1;background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element3.style.cssText += 'transition: .8s cubic-bezier(.25,.8,.5,1);border-radius: 50%;transform: translate(-50%, -47%);height:8px;width:8px;z-index:1;background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element6.style.cssText += 'transition: 200ms cubic-bezier(.25,.8,.5,1);border-radius: 50%;transform: translate(-50%, -47%);height:30px;width:45px;z-index:1;background: rgb(255, 81, 81);visibility:hidden;position: absolute;left:' + positionLeft + '%';
          } else {
            document.querySelector('.tick-size .v-slider__thumb').style.visibility = 'hidden';
            element.style.cssText += 'height:30px;width:30px;border-radius: 50%;transition: 0.6s cubic-bezier(.25,.8,.5,1);transform: translate(-50%, -50%);background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element2.style.cssText += 'height:40px;width:40px;border-radius: 50%;transition: .3s cubic-bezier(.25,.8,.5,1);transform: translate(-50%, -50%);background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element3.style.cssText += 'height:24px;width:24px;border-radius: 50%;transition: 0.9s cubic-bezier(.25,.8,.5,1);transform: translate(-48%, -47%);background: rgb(255, 81, 81);position: absolute;left:' + positionLeft + '%';
            element6.style.cssText += 'height:50px;width:50px;border-radius: 50%;position: absolute;transform: translate(-50%, -50%);background: rgb(255, 81, 81);left:' + positionLeft + '%';
          }

          const generalClassContainer = document.querySelector('.v-slider').appendChild(generalClass);
          generalClassContainer.appendChild(element6);
          generalClassContainer.appendChild(element2);
          generalClassContainer.appendChild(element);
          generalClassContainer.appendChild(element3);

          r += 3;
        }
      }
      window.runScriptA = 1;
    }
  }
}
function getPositionAtCenter(element) {
  const {top, left, width, height} = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2
  };
}

function getDistanceBetweenElements(a, b) {
  const aPosition = getPositionAtCenter(a);
  const bPosition = getPositionAtCenter(b);

  return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
}



export { whenDone, getDistanceBetweenElements};
